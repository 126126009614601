import React from 'react';
// import { TileContainer } from '@vds/tiles';
import { TileContainer } from "@vds/tiles";
import { Body, Micro, Title } from '@vds/typography';
import './CourseCards.scss';
import { Icon } from '@vds/icons';
import { Badge } from '@vds/badges';
import { ButtonIcon } from '@vds/button-icons';
import { TextLink } from '@vds/buttons';
// import { AnalyticsLibrary } from 'ysb-ui-libraries';
import { useSelector} from 'react-redux'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { pageLoadStarted,contentClick } from '../../../common/Analytics/GAWrapper';
import ResourceTag from '../ResourceTag/resourceTag';
import { getWithExpiry } from '../../../utility/utils';
const CourseCardComponent = (props) => {
  const registerAemData = useSelector((state) => state.Aem.registerData);

  let history = useNavigate();
  const { t, i18n } = useTranslation();
  const suggestions = getWithExpiry('suggestion');
  let courseTags = [];
  for(let item in suggestions?.courses){
    if(item === props?.data?.courseId){
      
      courseTags = suggestions?.courses[item]?.tags||[]; //course topics
    }
  }

  if (registerAemData &&
    registerAemData.registerModule &&
    registerAemData.registerModule.topics &&
    courseTags != null) {
    courseTags = courseTags.filter(item => registerAemData.registerModule.topics.filter(topicItem => topicItem.label === item).length > 0);
  }

  //Duration conversion for language
  const getTimeInLanguage = (time) => {
    return time?.replace('minutes', t('minutes'));
  }

  const handleClick = () => {

    console.log("Inside on click of quick tips ")
    // AnalyticsLibrary.Track.pageLoadStarted("en",props?.data?.label,"Infohub",localStorage.getItem("user"))
    //alert('Tile is clickedbbbbb')
    // AnalyticsLibrary.Track.Content_Click("", window.location.origin+"/course-details/" + props.data.courseId, props.tags,localStorage.getItem("user"));
    // navigate("/account")
    if(props?.data?.format===('Course'||'Curso'||'course'||'curso'))
    {
    contentClick(
      props?.data?.label,
      window.location.host + `/course-details/${props.data.courseId}`,
      courseTags?.filter(Boolean)?.sort()?.map((tag)=>t(tag))?.slice(0,4)?.join(', ')
    );
    }
    //pageLoadStarted(props?.data?.label?.slice(0, 72), window.location.href,"")
    window.scrollTo(0, 0);
    localStorage.setItem("courseUrl",window.location.href)
    history(`/course-details/${props.data.courseId}`)
  }
  
  return (
    <TileContainer
      ariaLabel={props?.data?.label?.slice(0, 72)}
      padding='0px'
      aspectRatio='3:4'// changed to 3:3.8 from 3:4.1 only for path desc
      backgroundColor="white"
      showBorder={false}
      showDropShadow={true}
      tabIndex={-1}
      onClick={e => {
        if(props?.data?.format===('Course'||'Curso'||'course'||'curso'))
    {
        contentClick(
          props?.data?.label,
          window.location.host + `/course-details/${props.data.courseId}`,
          courseTags?.filter(Boolean)?.sort()?.map((tag)=>t(tag))?.slice(0,4)?.join(', ')
        );}
       // AnalyticsLibrary.Track.pageLoadStarted("en",props?.data?.label,"Infohub",localStorage.getItem("user"))
        //alert('Tile is clickedbbbbb')
       // AnalyticsLibrary.Track.Content_Click("", window.location.origin+"/course-details/" + props.data.courseId, props.tags,localStorage.getItem("user"));
       window.scrollTo(0, 0);
       history(`/course-details/${props.data.courseId}`)
    }}
    >
      <div className="secondaryCourceCard">
        <div className='image-icons'>
          {props?.data?.tag &&
            <ResourceTag text={t(props?.data?.tag)}/>
          }
          {props?.data?.showBookmarkIcon &&
            <span className='ml-auto' role='button'>
              <ButtonIcon
                kind="lowContrast"
                size="small"
                surfaceType="media"
                renderIcon={(props) => <Icon name="bookmark" {...props} />}
              />
            </span>
          }
        </div>
        <div className="image">
          <img src={props?.data?.desktopImage} alt={props?.data?.label} />
        </div>
        <div className="description pa-6X sm-pa-6X">
          <div className='label mb-3X sm-mb-2X'>
            <Micro>
              <div className='course-label'  aria-label={props?.data?.resourceType}>
                <b>{t(props?.data?.format)}</b>
                {props?.data?.resourceType &&
                  <span className='dot-wrap mh-2X'></span>}
                <span className=''>{t(props?.data?.resourceType)}</span>
              </div>
            </Micro>
          </div>
          <div className="title-label label mb-3X sm-mb-2X"  aria-label={props?.data?.label}>
            <Title bold={false} color='#000000' size='medium'> {props?.data?.label?.slice(0, 72)}</Title>
          </div>
          <div className="durations mt-auto">
          <div className='textarea'>
            <div className="duration-text"  aria-label={props?.data?.dateAndTimeValue}>
              {props?.data?.duration?.preTextIcon && <Icon
                name={props?.data?.duration?.preTextIcon ? props?.data?.duration?.preTextIcon : "calendar"}
                size="small"
                color={props?.data?.duration?.color}
              />}
              {props.data?.duration?.preText && (<Micro
                size="small"
                color='#6F7171'
                bold={true}
                primitive="span">
                <span className='mr-2X'> {t(props?.data?.duration?.preText)}</span>
              </Micro>)}
              {props?.data?.duration?.icon && props?.data?.duration?.icon != "" && <Icon
                name={props?.data?.duration?.icon ? props?.data?.duration?.icon : "count-down"}
                size="small"
                color={props?.data?.duration?.color}
              />}
              {props.data?.dateAndTimeValue && (<Micro
                size="small"
                color='#6F7171'
                bold={true}
                primitive="span">
                <span className='mr-2X'> {t(getTimeInLanguage(props?.data?.dateAndTimeValue))}</span>
              </Micro>)}
              <Micro
                size="small"
                color={props?.data?.duration?.color}
                bold={true}
                primitive="span">
                <span className="ml-2X">{t(getTimeInLanguage(props?.data?.duration?.time))}</span>
              </Micro>
              {props.data?.duration?.extLink && (
                <TextLink
                  type='inline'
                  size='small'
                  color={props?.data?.duration?.color}
                  onClick={e => alert('TextLink clicked')}>
                  <span className="ml-2X">{props?.data?.duration?.extLink?.text}</span>
                </TextLink>)}
            </div>

            <div>
              <ButtonIcon
              role="link"
              tabindex={0}
               ariaLabel={`${props?.data?.ariaLabel ? props?.data?.ariaLabel?.replace('undefined',''):""} ${props?.data?.label} ${t(props?.data?.format)}`}
                kind="ghost"
                size="large"
                renderIcon={(props) => <Icon name="right-arrow" {...props}  />}
                onClick={(e)=>handleClick()}
              />
            </div>
          </div>
          {props?.data?.shortDesc && <div className="pv-4X ph-6X path-des bg-gray">
              <Icon name='ideas-solutions'></Icon>

              <Body size='small' bold>
                <div className='pl-2X' >
                  {props?.data?.shortDesc?.slice(0, 72)}
                </div>
              </Body>

            </div>}
            </div>
        </div>

      </div>
      
    </TileContainer>
  );
};

export default CourseCardComponent;
  