/** @format */
import { Button, TextLink } from '@vds/buttons';
import { Body, Title } from '@vds/typography';
import { useNavigate } from 'react-router-dom';
import { pageLoadStarted, userRegistrationClick } from '../common/Analytics/GAWrapper';
import './StartCourseComponent.scss';
import { useSelector } from 'react-redux';
import store from '../store/store';
import { updateRegisterTriggeredFrom, updateSelectedCourse } from '../store/aem';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const StartCourseComponent = (props) => {
  const aemData = useSelector((state) => state.Aem.data);
  const { t, i18n } = useTranslation();
  const isCapitalOffering = window?.location?.pathname?.indexOf('capital-offering') != -1 ? true : false;
  const [utmUrl, setUtmUrl] = useState('');
  const navigate = useNavigate();

  if (window.MP) {
    window.MP.SrcUrl = window.location.href;
  }

  const {
    registerSecTitle,
    registerSecDesc,
    registerButtonLabel,
    alreadyMemberLabel,
    signInLabel,
    resourceType,
    collectionId,
    needSignInRedirect = true,
    isLearningPath,
    recordedEventTag
  } = props;

  useEffect(() => {
    window?.location?.search?.includes('utm_') ? localStorage.setItem('utmUrl', `${window.location.search.substring(1)}`) : null
    setUtmUrl(window?.location?.search?.includes('utm_') ? `${window.location.search.substring(1)}` : (localStorage.getItem('utmUrl') ? localStorage.getItem('utmUrl') : ''))
  }, [location])

  return (
    <div
      className="pa-6X mb-8X sm-mb-6X n-textLeft bg-monarch-stone"
      style={{ borderRadius: '8px' }}
    >
      <Title size="medium" bold={true} color="#000000">
        {isCapitalOffering ? aemData?.capitalOffering?.detailsRegisterText : recordedEventTag ? t("Register now to take this event.") : registerSecTitle}
      </Title>
      <div className="mt-2X">
        <Body size="large" color="#000000" bold={false}>
          {registerSecDesc}
        </Body>
      </div>
      <div className="mt-6X">
        <span style={{ display: "flex", flexDirection: "row" }}>
          <Button
            size="large"
            disabled={false}
            use="primary"
            onClick={(e) => {
              if (isLearningPath) {
                localStorage.setItem('learningPathId', collectionId);
              }
              if (collectionId) {
                store.dispatch(updateSelectedCourse(collectionId));
                store.dispatch(updateRegisterTriggeredFrom("courseDetails"));
                if (isCapitalOffering) {
                  localStorage.setItem('isCapitalOffering', true);
                } else {
                  localStorage.setItem('collectionId', collectionId);
                }
              } else {
                store.dispatch(updateRegisterTriggeredFrom(location.pathname));
              }
              localStorage.setItem("prevUrl", window.location.href)
              navigate("/onboarding");
              console.log(`collectionId=${collectionId};domain=${global.config.APP_COOKIE_DOMAIN};path=/`);
              // if(needSignInRedirect) {
              //   store.dispatch(updateSelectedCourse(collectionId));
              //   store.dispatch(updateRegisterTriggeredFrom("courseDetails"));
              // }
              document.cookie = `collectionId=${collectionId};domain=${global.config.APP_COOKIE_DOMAIN};path=/`
              //document.cookie = `RedirectCollectionId=${collectionId};domain=${global.config.APP_COOKIE_DOMAIN};path=/`
              userRegistrationClick('Sidebar', registerButtonLabel, window.location.host + '/onboarding')
              // pageLoadStarted('OnboardingPage',window.location.href,selectedTopics?.toString());
            }}
          >
            {registerButtonLabel}
          </Button>
          {isCapitalOffering &&
            <> &nbsp; &nbsp;
              <TextLink type="standAlone" onClick={(e) => {
                document.cookie = `collectionId=${collectionId};domain=${global.config.APP_COOKIE_DOMAIN};path=/`
                document.cookie = `firstTimeLogin=true;domain=${global.config.APP_COOKIE_DOMAIN};path=/`
                //document.cookie = `RedirectCollectionId=${collectionId};domain=${global.config.APP_COOKIE_DOMAIN};path=/`
                let loginRedirect = global.config.CIAM_SIGN_IN_URL;
                let lng = localStorage.getItem("lng") && localStorage.getItem("lng") === 'es' ? 'es' : 'en';

                if (utmUrl === '') {
                  if (collectionId) {
                    if (lng === "es") {
                      loginRedirect = global.config.SSO_LOGIN_COURSE.replace('course-details', 'es/course-details') + collectionId + `?ciamLang=${lng}`;
                    } else {
                      loginRedirect = global.config.SSO_LOGIN_COURSE + collectionId + `?ciamLang=${lng}`;
                    }
                  } else {
                    loginRedirect = global.config.CIAM_SIGN_IN_URL + encodeURI(`${global.config.URL_MAPPING_AUTHENTICATED_USER[location.pathname]}?ciamLang=${lng}`);
                  }
                } else {
                  if (collectionId) {
                    if (lng === "es") {
                      loginRedirect = global.config.SSO_LOGIN_COURSE.replace('course-details', 'es/course-details') + collectionId + `?ciamLang=${lng}&${utmUrl}`;
                    } else {
                      loginRedirect = global.config.SSO_LOGIN_COURSE + collectionId + `?ciamLang=${lng}&${utmUrl}`;
                    }
                  } else {
                    loginRedirect = global.config.CIAM_SIGN_IN_URL + encodeURI(`${global.config.URL_MAPPING_AUTHENTICATED_USER[location.pathname]}?ciamLang=${lng}&${utmUrl}`);
                  }
                }
                // if(needSignInRedirect) {
                //   loginRedirect = global.config.SSO_LOGIN_COURSE + collectionId
                // }
                window.location.href = loginRedirect;
                localStorage.removeItem('utmUrl');
              }}
                ariaLabel={signInLabel} >
                {signInLabel}
              </TextLink> </>}
        </span>
      </div>

      {!isCapitalOffering && alreadyMemberLabel && <div className="mt-8X">
        <Body size="large" color="#000000" bold={false}>
          {alreadyMemberLabel}
        </Body>
      </div>}
      <div className="mt-4X">
        {!isCapitalOffering && <TextLink type="standAlone" onClick={(e) => {
          document.cookie = `collectionId=${collectionId};domain=${global.config.APP_COOKIE_DOMAIN};path=/`
          document.cookie = `firstTimeLogin=true;domain=${global.config.APP_COOKIE_DOMAIN};path=/`
          //document.cookie = `RedirectCollectionId=${collectionId};domain=${global.config.APP_COOKIE_DOMAIN};path=/`
          let loginRedirect = global.config.CIAM_SIGN_IN_URL;
          let lng = localStorage.getItem("lng") && localStorage.getItem("lng") === 'es' ? 'es' : 'en';
          // if(needSignInRedirect) {
          //   loginRedirect = global.config.SSO_LOGIN_COURSE + collectionId
          // }
          if (utmUrl === '') {
            if (collectionId) {
              loginRedirect = global.config.SSO_LOGIN_COURSE + collectionId + `?ciamLang=${lng}`;
            } else {
              loginRedirect = global.config.CIAM_SIGN_IN_URL + encodeURI(`${global.config.URL_MAPPING_AUTHENTICATED_USER[location.pathname]}?ciamLang=${lng}`);
            }
          } else {
            if (collectionId) {
              loginRedirect = global.config.SSO_LOGIN_COURSE + collectionId + `?ciamLang=${lng}&${utmUrl}`;
            } else {
              loginRedirect = global.config.CIAM_SIGN_IN_URL + encodeURI(`${global.config.URL_MAPPING_AUTHENTICATED_USER[location.pathname]}?ciamLang=${lng}&${utmUrl}`);
            }
          }
          console.log("^^loginRedirect", loginRedirect);
          window.location.href = loginRedirect;
          localStorage.removeItem('utmUrl');
        }}
          ariaLabel={signInLabel} >
          {signInLabel}
        </TextLink>}
      </div>
    </div>
  );
};

export default StartCourseComponent;
