export const pageLoadStarted = ( pageTitle,page_referrer,topics,currentStep=null) => {
  // const selectedTags = useSelector((state) => state.AdobeResources.selectedTags);
  let language=localStorage.getItem("lng")==='en'?'en-us':localStorage.getItem("lng")==='es'?'es-es':'en-us';
  // var selectedTopics = topicsName.filter(topic => selectedTags.indexOf(topic.data) !== -1);
  let pageReferrer = page_referrer === null ? '' : page_referrer.includes("null") ? page_referrer.replace("null", "") : page_referrer;
  const dataLayer = window.dataLayer || [];
  let pageLocation= window.location.href;

  if(pageLocation.includes("/onboarding")&& currentStep){
    if(!pageLocation.includes("-step")){
      pageLocation+=` -step${currentStep}`;
    }
  }
  dataLayer.push({ event_data: null }); // Clear the previous event_data object.
  dataLayer.push({ page_data: null }); // Clear the previous page_data object.
  dataLayer.push({
    event: 'page_load_started',
    detailed_event: 'Page Load Started',
    event_data: {
      profile_status: "",
      topics: topics
  },
    page_data: {
      country: 'US',
      language:  language,
      page_referrer: pageReferrer,
      page_location:pageLocation,
      page_title: pageTitle,
  },
    user_data: {
      user_login_state: "guest",
      user_id: ""
    },
  });
};

export const pageLoad_Started = ( pageTitle,page_referrer,topics,newContentAdded) => {
  // const selectedTags = useSelector((state) => state.AdobeResources.selectedTags);
  let language=localStorage.getItem("lng")==='en'?'en-us':localStorage.getItem("lng")==='es'?'es-es':'en-us';
  // var selectedTopics = topicsName.filter(topic => selectedTags.indexOf(topic.data) !== -1);
  let pageReferrer = page_referrer === null ? '' : page_referrer.includes("null") ? page_referrer.replace("null", "") : page_referrer;
  const dataLayer = window.dataLayer || [];

  dataLayer.push({ event_data: null }); // Clear the previous event_data object.
  dataLayer.push({ page_data: null }); // Clear the previous page_data object.
  dataLayer.push({
    event: 'page_load_started',
    detailed_event: 'Page Load Started',
    event_data: {
      profile_status: "",
      topics: topics,
      new_content_added: newContentAdded

  },
    page_data: {
      country: 'US',
      language:  language,
      page_referrer: pageReferrer,
      page_location:window.location.href,
      page_title: pageTitle,
  },
    user: {
      user_login_state: 'guest',
      user_id: ""
    },
  });
};

export const pageLoadCompleted = () => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: 'page_view',
    detailed_event: 'Page Load Completed',
  });
};

export const topicSearch = (topic) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({ event_data: null }); // Clear the previous event_data object.
  dataLayer.push({
    event: 'topic_search',
    detailed_event: 'topic_search',
    event_data: {
      topic: topic,
    },
  });
};

export const userRegistrationStep = (stepName, stepNumber, topics, isPartner) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({ event_data: null }); // Clear the previous event_data object.
  dataLayer.push({
    event: 'registration_step_view',
    detailed_event: 'User Registration Step Encountered',
    event_data: {
      ...(isPartner && {name: 'partner registration'}),
      step_name: stepName,
      step_number: stepNumber,
      topics: topics,
    },    
    user_data: {
      user_type: isPartner ? 'partner' : 'learner'
    }
  });
};
export const userRegistrationClick = (clickLocation, linkText, linkUrl) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({ event_data: null }); // Clear the previous event_data object.
  dataLayer.push({
    event: 'user_registration_click',
    detailed_event: 'User Registration Click',
    event_data: {
      click_location: clickLocation,
      link_text: linkText,
      link_url: linkUrl,
    },
  });
};
export const contentClick = (linkText, linkUrl, topics) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({ event_data: null }); // Clear the previous event_data object.
  dataLayer.push({ 
    event: 'content_click',
    detailed_event: 'Content Click',
    event_data: {
      link_text: linkText,
      link_url: linkUrl,
      topics: topics,
    },
    user_data: {
      user_id: ""
  }
  });
};
export const userRegistered = (businessZip, topics, hashedEmail, userId, userType, isPartner, partnerData) => {
  const dataLayer = window.dataLayer || [];

dataLayer.push({ event_data: null });  // Clear the previous event_data object.
dataLayer.push({
  event: "sign_up",
  detailed_event: "User Registered",
    event_data: {
      ...(isPartner && {name: 'partner registration'}),
      ...(isPartner && {access_code: partnerData?.accessCode}),
      business_zip: businessZip,
      topics: topics
    },
    user_data: {
      user_id: userId,
      user_type: userType
    },
    // partner = trackingData.partner;
    // channel = trackingData.channel;
    hashedEmail: hashedEmail
});

};
export const userSignedIn = () => {
  const dataLayer = window.dataLayer || [];
 
  dataLayer.push({
    event: "login",
    detailed_event: "User Signed In",
      user_data: {
          user_login_state: "logged in"
      }
  });
};
// export const userSignedIn = (hashedEmail) => {
//   const dataLayer = window.dataLayer || [];
 
//   dataLayer.push({
//     event: "login",
//     detailed_event: "User Signed In",
//       user_data: {
//           user_login_state: "logged in",
//           user_id:hashedEmail
//       }
//   });
// };
export const errorMessage=(errorMsg)=>
{
  const dataLayer = window.dataLayer || [];
 
  dataLayer.push({ event_data: null });  // Clear the previous event_data object.
  dataLayer.push({
  "event": "site_error",
  "detailed_event": "Error Message Presented",
    "event_data": {
      "error_message": errorMsg,
        "type": "System"
    }
});
}
export const videoStarted=(videoDuration,videoTitle,src)=>
{
const dataLayer =  window.dataLayer || [];
dataLayer.push({ event_data: null });  // Clear the previous event_data object.
dataLayer.push({
  "event": "video_start",
  "detailed_event": "Video Started",
    "event_data": {
        "identifier": src,
        "video_duration": videoDuration,
        "video_title": videoTitle
    }
});}

export const videoMileStoneReached=(videoDuration,videoTitle,mileStone,src)=>
{
  const dataLayer =   window.dataLayer || [];
dataLayer.push({ event_data: null });  // Clear the previous event_data object.
dataLayer.push({
  "event": "video_progress",
  "detailed_event": "Video Milestone Reached",
    "event_data": {
        "identifier": src,
        "video_duration": videoDuration,
        "video_milestones_reached": mileStone,
        "video_title": videoTitle
    }
});}
export const videoCompleted=(videoDuration,videoTitle,src)=>
{
  const dataLayer = window.dataLayer || [];
dataLayer.push({ event_data: null });  // Clear the previous event_data object.
dataLayer.push({
  "event": "video_complete",
  "detailed_event": "Video Completed",
    "event_data": {
        "identifier": src,
        "video_duration": videoDuration,
        "video_title": videoTitle   
    }
});}

export const searchFilter=(language)=>
{
  const dataLayer = window.dataLayer || [];
  dataLayer.push({ page_data: null });  // Clear the previous page_data object.
  dataLayer.push({
    "event": "search_filter",
    "detailed_event": "Search Filter",
      "page_data": {
          "language": language
      }
  });
}